<template>
    <div>
        <v-sheet color="secondary" height="200px" id="container">
            <div id="background"></div>
            <v-row no-gutters class="fill-height" align="center" justify="center">
                <v-col
                    cols="auto"
                    class="text-center text-uppercase text-md-h3 text-sm-h4 text-h4 main-font primary--text"
                    id="title"
                >
                    <v-icon
                        left color="primary"
                        :size="$vuetify.breakpoint.mdAndUp ? 50 : 40"
                        :class="$vuetify.breakpoint.mdAndUp ? 'pb-4' : 'pb-2'"
                    >
                        {{ $route.meta.icon }}
                    </v-icon>
                    {{ $t("views." + $route.name + ".title") }}
                </v-col>
            </v-row>
        </v-sheet>
        <v-container fluid class="px-md-16 py-md-8 pa-8 mb-8" id="content">
            <slot></slot>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "content-layout"
    };
</script>

<style scoped>
    #container {
        position: relative;
    }
    #background {
        z-index: 0;
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
    }
    #background::before {
        z-index: 0;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 0.2;

        background: url("@/assets/img/bgs/1.png");
        background-size: cover;
    }
    #title {
        z-index: 1;
    }
    #content {
        max-width: 1200px;
    }
</style>