<template>
    <ContentLayout>
        <v-row justify="center">
            <v-col cols="12" align="center">
                <div v-for="(text, t) in $t(`views.lighting.text`)" :key="t">
                    {{ text }}
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-for="(image, i) in images" :key="i">
                <v-card :class="{ 'ma-2': $vuetify.breakpoint.mdAndUp }" class="image">
                    <v-img
                        color="primary" height="300"
                        :src="image"
                        @click="overlay.current = image; overlay.value = true"
                    ></v-img>
                </v-card>
            </v-col>
        </v-row>
        <ImageCarouselOverlay
            :value="overlay.value"
            :images="images"
            :current="overlay.current"
            @close="overlay.value = false"
            cycle hide-delimiters
        ></ImageCarouselOverlay>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    import ImageCarouselOverlay from "@/components/elements/ImageCarouselOverlay";

    export default {
        name: "lighting-view",
        components: { ContentLayout, ImageCarouselOverlay },
        data: () => ({
            images: [
                require("@/assets/img/lighting/1.jpg"),
                require("@/assets/img/lighting/2.jpg"),
                require("@/assets/img/lighting/3.jpg"),
                require("@/assets/img/lighting/4.jpg"),
                require("@/assets/img/lighting/5.jpg"),
                require("@/assets/img/lighting/6.jpg"),
                require("@/assets/img/lighting/7.jpg"),
                require("@/assets/img/lighting/8.jpg")
            ],
            overlay: {
                value: false,
                current: null
            }
        })
    };
</script>

<style scoped>
    .image {
        cursor: zoom-in;
    }
</style>