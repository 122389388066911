<template>
    <ContentLayout>
        <v-row>
            <v-col cols="12" md="6">
                <v-card flat :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }">
                    <v-card-title class="text-h5 text-sm-h4 primary--text text-uppercase px-0">{{ $t("views.contact.contact-us") }}</v-card-title>
                    <v-card-text class="text-body-1 px-0">
                        <v-list two-line color="transparent">
                            <v-list-item :href="_env.VUE_APP_ADDRESS_LINK" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-map-marker
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">{{ _env.VUE_APP_ADDRESS }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("views.contact.address") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :href="'tel:' + _env.VUE_APP_PHONE" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-phone
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ _env.VUE_APP_PHONE }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("views.contact.phone") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <!--<v-list-item :href="'tel:' + _env.VUE_APP_FAX" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-fax
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ _env.VUE_APP_FAX }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("views.contact.fax") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>-->
                            <v-list-item :href="'mailto:' + _env.VUE_APP_EMAIL" target="_blank">
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-email
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ _env.VUE_APP_EMAIL }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t("views.contact.email") }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card elevation="4" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }">
                    <v-sheet color="secondary" height="440px" id="map-wrapper">
                        <div id="map">
                            <iframe
                                width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                                :src="'https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=' + $i18n.locale + '&amp;q=' + _env.VUE_APP_ADDRESS + '&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'"
                            ></iframe>
                        </div>
                        <v-row no-gutters class="fill-height" align="center" justify="center" id="map-loading">
                            <v-col cols="auto" class="text-h2">
                                <!--<span class="main-font">Map</span>-->
                                <v-progress-circular indeterminate size="80" color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-card>
            </v-col>
            <v-col cols="12" class="mt-8">
                <v-form ref="form" @submit.prevent @submit="submit">
                    <v-card outlined>
                        <v-card-title class="text-h6 text-sm-h5 primary--text text-uppercase my-1">{{ $t("views.contact.message") }}</v-card-title>
                        <v-card-text class="text-body-1">
                            <v-row dense>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        :label="$t('form.fields.first_name')"
                                        v-model="form.first_name"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="30" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        :label="$t('form.fields.last_name')"
                                        v-model="form.last_name"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="30" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        :label="$t('form.fields.email')"
                                        v-model="form.email"
                                        outlined
                                        :rules="[ $data.customRules.required, $data.customRules.email ]" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" lg="3">
                                    <v-text-field
                                        :label="$t('form.fields.phone')"
                                        v-model="form.phone"
                                        v-mask="'(###) ###-####'"
                                        outlined
                                        :rules="[ $data.customRules.required, $data.customRules.phone ]" :disabled="submitting"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        :label="$t('form.fields.message')"
                                        v-model="form.message"
                                        outlined counter="500"
                                        :rules="[ $data.customRules.required ]" maxlength="500" :disabled="submitting"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" large type="submit" :loading="submitting" outlined>
                                <v-icon left>mdi-send</v-icon>{{ $t('views.contact.submit.action') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    export default {
        name: "contact-view",
        components: { ContentLayout },
        data: () => ({
            submitting: false,
            form: {
                first_name: null,
                last_name: null,
                phone: null,
                email: null,
                message: null
            }
        }),
        methods: {
            submit() {
                if(this.$refs && this.$refs.form && this.$refs.form.validate()) {
                    this.submitting = true;
                    this._request("forms/contact", this.form).then(response => {
                        if(response.error) {
                            this.$root.snack.show({
                                //text: response.error,
                                text: this.$i18n.t("views.contact.submit.error"),
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                            return;
                        }
                        
                        this.form.message = null;
                        this.$refs.form.resetValidation();
                        
                        this.$root.snack.show({
                            text: this.$i18n.t("views.contact.submit.success"),
                            icon: "mdi-check",
                            color: "success"
                        });

                    }).finally(() => {
                        this.submitting = false;
                    });
                } else {
                    this.$root.snack.show({
                        text: this.$i18n.t("form.error"),
                        icon: "mdi-alert-circle",
                        color: "error"
                    });
                }
            }
        }
    };
</script>

<style scoped>
    #map-wrapper {
        position: relative;
    }
    #map {
        z-index: 2;
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }
    #map-loading {
        z-index: 1;
    }
</style>