import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import i18n from './i18n';
import en from 'vuetify/src/locale/en.ts';
import fr from 'vuetify/src/locale/fr.ts';

Vue.use(Vuetify);

export default new Vuetify({
    //lang: {
    //    t: (key, ...params) => i18n.t(key, params)
    //},
    lang: {
        locales: { en, fr },
        current: 'fr',
    },
    theme: {
        themes: {
            light: {
                primary: '#427400', // dark green
                secondary: '#bbea7a', // green
                accent: '#fdba14', // orange
            },
        },
    },
});
