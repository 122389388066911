import HomeView from "./views/Home";
import IrrigationView from "./views/Irrigation";
import LightingView from "./views/Lighting";
import ContactView from "./views/Contact";

export default [
    {
        path: "/home", name: "home", component: HomeView,
        meta: {
            icon: "mdi-home-outline"
        }
    },
    {
        path: "/irrigation", name: "irrigation", component: IrrigationView,
        meta: {
            icon: "mdi-sprinkler"
        }
    },
    {
        path: "/lighting", name: "lighting", component: LightingView,
        meta: {
            icon: "mdi-outdoor-lamp"
        }
    },
    {
        path: "/contact", name: "contact", component: ContactView,
        meta: {
            icon: "mdi-message-text-outline"
        }
    },
    { path: "*", redirect: "home" },
];