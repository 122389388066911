<template>
    <v-app id="app">
        <v-navigation-drawer v-model="drawer" app temporary dark color="primary">
            <div class="background-absolute"></div>
            <v-img src="@/assets/img/logo.svg" contain class="ma-4 mt-12"></v-img>
            <div class="text-center my-12">
                <LanguageElement></LanguageElement>
            </div>
            <v-divider></v-divider>
            <v-list nav class="text-uppercase">
                <v-list-item
                    v-for="route in routes"
                    :key="route.name"
                    link
                    :to="route.name"
                >
                    <v-list-item-icon>
                        <v-icon>{{ route.meta.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("views." + route.name + ".title") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <v-app-bar
                v-if="$vuetify.breakpoint.mdAndDown"
                app height="120px" color="primary" dark
                class="appbar"
            >
                <div class="background-absolute"></div>
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
                <v-spacer></v-spacer>
                <v-app-bar-title>
                    <router-link to="/">
                        <v-img src="@/assets/img/logo.svg" height="auto" width="260px" contain></v-img>
                    </router-link>
                </v-app-bar-title>
                <v-spacer></v-spacer>
                <LanguageElement icon></LanguageElement>
            </v-app-bar>
            <v-app-bar v-else app color="primary" height="120px" class="appbar">
                <div class="background-absolute"></div>
                <v-app-bar-title>
                    <router-link :to="{ name: 'home' }">
                        <v-img src="@/assets/img/logo.svg" height="auto" width="360px" contain></v-img>
                    </router-link>
                </v-app-bar-title>
                <v-spacer></v-spacer>
                <v-btn
                    v-for="route in routes"
                    large depressed color="primary"
                    :key="route.name" :to="route.name"
                    active-class="secondary primary--text"
                    class="mr-4"
                >
                    <v-icon v-if="!!route.meta && !!route.meta.icon" left>{{ route.meta.icon }}</v-icon>
                    {{ $t("views." + route.name + ".title") }}
                </v-btn>
                <v-btn
                    v-if="$vuetify.breakpoint.lgAndUp"
                    outlined large color="secondary"
                    active-class="secondary"
                    class="mr-4"
                    :href="'tel:' + _env.VUE_APP_PHONE" target="_blank"
                >
                    <template v-if="$vuetify.breakpoint.width > 1380">
                        <v-icon left size="22">mdi-phone</v-icon>
                        {{ $t("call-us") }}
                        <span class="accent--text ml-1 font-weight-black">{{ _env.VUE_APP_PHONE }}</span>
                    </template>
                    <v-icon v-else>mdi-phone</v-icon>
                </v-btn>
                <LanguageElement v-if="$vuetify.breakpoint.smAndUp"></LanguageElement>
                <v-app-bar-nav-icon @click="drawer = !drawer" class="primary--text"></v-app-bar-nav-icon>
            </v-app-bar>
            <router-view></router-view>
        </v-main>
        <v-footer padless absolute app inset class="secondary">
            <v-container fluid class="px-md-16 py-md-8 pa-8" id="footer-content">
                <v-row justify="center">
                    <v-col cols="12" md="auto" align="center">
                        <v-btn
                            :x-large="$vuetify.breakpoint.mdAndUp" large
                            :block="$vuetify.breakpoint.xs"
                            color="primary"
                            :href="'tel:' + _env.VUE_APP_PHONE" target="_blank"
                        >
                            <v-icon left size="22">mdi-phone</v-icon>
                            {{ $t("call-us") }} <span class="accent--text ml-1 font-weight-black">{{ _env.VUE_APP_PHONE }}</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="auto" align="center">
                        <v-btn
                            class="black--text"
                            :x-large="$vuetify.breakpoint.mdAndUp" large
                            :block="$vuetify.breakpoint.xs"
                            color="accent"
                            :to="{ name: 'contact' }"
                        >
                            <v-icon left size="22">mdi-check</v-icon>
                            {{ $t(`free-estimate`) }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" sm="4" md="3" align="center">
                        <v-icon color="primary" size="60">mdi-sprinkler</v-icon>
                        <div class="mt-4 text-uppercase">
                            {{ $t(`services[0]`) }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4" md="3" align="center">
                        <v-icon color="primary" size="60">mdi-outdoor-lamp</v-icon>
                        <div class="mt-4 text-uppercase">
                            {{ $t(`services[1]`) }}
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-card class="flex main-font" flat dark tile>
                <v-divider class="primary"></v-divider>
                <div class="secondary primary--text">
                    <v-row no-gutters justify="center">
                        <v-col cols="12" class="text-center mt-8 text-h6 main-font">
                            <v-icon left class="pb-1" color="primary">mdi-handshake</v-icon>
                            {{ $t(`partners`) }}
                        </v-col>
                        <v-col cols="12" id="partners">
                            <div>
                                <a
                                    v-for="(partner, p) in partners" :key="p"
                                    :href="partner.link" target="_blank"
                                    class="my-8 mx-8"
                                >
                                    <v-img
                                        :src="partner.image"
                                        width="160" height="60" contain
                                    ></v-img>
                                </a>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <v-card-title class="primary">
                    <v-row no-gutters>
                        <v-col cols="12" md="3" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                            <a :href="'tel:' + _env.VUE_APP_PHONE" class="white--text text-decoration-none" target="_blank">
                                <v-icon left>mdi-phone</v-icon> {{ _env.VUE_APP_PHONE }}
                            </a>
                        </v-col>
                        <v-col cols="12" md="6" class="text-center" :class="{ 'my-4': $vuetify.breakpoint.smAndDown }">
                            <a :href="_env.VUE_APP_ADDRESS_LINK" class="white--text text-decoration-none" target="_blank">
                                <v-icon left>mdi-map-marker</v-icon> {{ _env.VUE_APP_ADDRESS }}
                            </a>
                        </v-col>
                        <v-col cols="12" md="3" class="accent--text text-right" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                            <v-tooltip top v-for="link in links" :key="link.value">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-on="on" v-bind="attrs"
                                        class="mx-4" icon
                                        :href="link.value"
                                        target="_blank"
                                    >
                                        <v-icon size="26px">{{ link.icon }}</v-icon>
                                    </v-btn>
                                </template>
                                {{ link.text }}
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="py-2 secondary black--text text-caption">
                    <v-row no-gutters>
                        <v-col cols="12" md="6" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                            &copy; {{ new Date().getFullYear() }} {{ _env.VUE_APP_TITLE }} — {{ $t("copyright") }}
                        </v-col>
                        <v-col cols="12" md="6" class="text-right" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                            {{ $t("developed_by") }} 
                            <a
                                class="text-decoration-none"
                                :href="_env.VUE_APP_AUTHOR_WEBSITE"
                                target="_blank"
                            >
                                {{ _env.VUE_APP_AUTHOR_NAME }}
                            </a>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-footer>
        <SnackElement ref="snackElement"></SnackElement>
    </v-app>
</template>

<script>
    import SnackElement from "./elements/Snack";
    import LanguageElement from "./elements/Language";

    export default {
        name: "app",
        components: {
            SnackElement, LanguageElement
        },
        data: () => ({
            drawer: false,
            partners: [
                {
                    link: "https://www.irritrol.com",
                    image: require("@/assets/img/partners/irritrol.svg")
                },
                {
                    link: "https://www.rainbird.com",
                    image: require("@/assets/img/partners/rain-bird.svg")
                },
                {
                    link: "https://www.toro.com",
                    image: require("@/assets/img/partners/toro.svg")
                },
                {
                    link: "https://www.arrosagessimoneau.com/",
                    image: require("@/assets/img/partners/arrosages-simoneau.svg")
                },
                {
                    link: "https://www.nelsonirrigation.com",
                    image: require("@/assets/img/partners/nelson.svg")
                },
                {
                    link: "https://www.hunterindustries.com",
                    image: require("@/assets/img/partners/hunter.svg")
                },
                {
                    link: "https://www.kichler.com",
                    image: require("@/assets/img/partners/kichler.svg")
                },
                {
                    link: "https://www.albilegeant.com",
                    image: require("@/assets/img/partners/albi.png")
                },
                {
                    link: "https://www.paysagementlefebvre.ca",
                    image: require("@/assets/img/partners/paysagement-lefebvre.png")
                },
                {
                    link: "https://www.info-ex.com",
                    image: require("@/assets/img/partners/info-ex.png")
                },
                {
                    link: "https://www.gaube.maitreduvoyage.com",
                    image: require("@/assets/img/partners/martine.png")
                },
                {
                    link: "https://www.encodev.com",
                    image: require("@/assets/img/partners/encodev.svg")
                }
            ],
            links: [
                {
                    icon: "mdi-facebook",
                    text: "Facebook",
                    value: "https://www.facebook.com/p/IRRIGATION-MG-100039796814875"
                }
            ]
        }),
        computed: {
            routes() {
                return this.$router.options.routes.filter(route => !!route.meta);
            }
        },
        watch: {
            "$route": function() {
                this.updateRoute();
            }
        },
        methods: {
            updateRoute() {
                // when route change, scroll to top and update document title
                window.scrollTo(0, 0);
                let title = "";
                if(this.$route.name != "home") {
                    title = this.$t(`views.${this.$route.name}.title`) + " - ";
                }
                title += this._env.VUE_APP_TITLE;
                document.title = title;
            }
        },
        created() {
            this.updateRoute();
        },
        mounted() {
            this.$root.snack = this.$refs.snackElement;
        }
    };
</script>

<style>
    @font-face {
        font-family: 'Aachen Bold';
        src: url('@/assets/fonts/Aachen Bold.ttf') format('truetype-variations');
        font-weight: 200 800;
    }
    #app .main-font {
        font-family: 'Aachen Bold' !important;
        font-weight: 400 !important;
    }

    .background-absolute {
        z-index: 0;
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
    }

    .background-absolute::before {
        z-index: 1;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 0.4;

        background: url("@/assets/img/bgs/2.jpg");
        background-size: cover;
    }
    .background-absolute::after {
        z-index: 1;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 1;

        background: linear-gradient(to right, transparent 0%, #427400 100%);
    }
    #footer-content {
        max-width: 1200px;
    }

    #partners {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        box-sizing: border-box;
    }
    #partners > div {
        display: inline-block;
        animation: marquee 50s linear infinite;
    }
    #partners:hover > div {
        animation-play-state: paused;
    }
    #partners a {
        display: inline-block;
        opacity: 0.7;
    }
    #partners a:hover {
        opacity: 1;
    }
    @keyframes marquee {
        0% {
            transform: translateX(20vw);
        }
        50% {
            transform: translateX(calc(-100% + 80vw));
        }
        100% {
            transform: translateX(20vw);
        }
    }
</style>