<template>
    <v-overlay
        :value="value"
        id="overlay"
    >
        <v-carousel
            v-model="currentIndex"
            show-arrows-on-hover
            :cycle="cycle"
            :hide-delimiters="hideDelimiters"
            height="100%"
            class="primary background"
        >
            <v-carousel-item v-for="(image, po) in images" :key="po" height="100%">
                <v-img
                    :src="image.indexOf('base64') !== -1 ? image : (!!path ? path : '') + image"
                    height="100%" contain
                ></v-img>
            </v-carousel-item>
        </v-carousel>
        <v-btn
            outlined text absolute top right fab
            @click="close"
            class="action mt-12"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-overlay>
</template>

<script>
    export default {
        name: "image-carousel-overlay-element",
        props: {
            value: {
                type: Boolean,
                required: true
            },
            images: {
                type: Array,
                required: true
            },
            current: {
                type: String
            },
            path: {
                type: String
            },
            cycle: {
                type: Boolean,
                default: false
            },
            hideDelimiters: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            currentIndex: 0
        }),
        watch: {
            //value: function() {
            //    console.log("watch value");
            //},
            current: function(value) {
                this.currentIndex = this.images.indexOf(value);
            },
        },
        methods: {
            close() {
                this.$emit("close", null);
            }
        }
    }
</script>

<style scoped>
    #overlay {
        z-index: 10 !important;
    }

    #overlay >>> .v-overlay__content {
        height: 100%;
        width: 100%;
    }

    .action {
        z-index: 9;
    }
</style>