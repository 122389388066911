<template>
    <div>
        <v-carousel
            hide-delimiter-background show-arrows-on-hover cycle
            id="carousel"
            interval="18000"
            height="100%"
        >
            <v-carousel-item
                dark
            > <!-- src="@/assets/img/bgs/3.jpg" -->
                <video autoplay muted loop>
                    <source src="@/assets/videos/bmw.mp4" type="video/mp4">
                </video>
                <v-row no-gutters class="fill-height background" align="center" justify="center">
                    <v-col cols="auto text-center">
                        <div
                            class="main-font text-shadow"
                            :class="{ 'text-h3': $vuetify.breakpoint.mdAndUp, 'text-h4': $vuetify.breakpoint.smAndDown }"
                        >
                            {{ $t(`views.home.slides[0].title`) }}
                        </div>
                        <div
                            class="main-font mt-8 text-shadow"
                            :class="{ 'text-h5': $vuetify.breakpoint.mdAndUp, 'text-h6': $vuetify.breakpoint.smAndDown }"
                        >
                            {{ $t(`views.home.slides[0].subtitle`) }}
                        </div>
                    </v-col>
                </v-row>
            </v-carousel-item>
            <v-carousel-item
                src="@/assets/img/bgs/car.jpg"
                dark
                id="car"
            >
                <v-row no-gutters class="fill-height background" align="center" justify="center">
                    <v-col cols="auto text-center">
                        <div
                            class="main-font text-shadow"
                            :class="{ 'text-h3': $vuetify.breakpoint.mdAndUp, 'text-h4': $vuetify.breakpoint.smAndDown }"
                        >
                            {{ $t(`views.home.slides[1].title`) }}
                        </div>
                        <div
                            class="main-font mt-8 text-shadow"
                            :class="{ 'text-h5': $vuetify.breakpoint.mdAndUp, 'text-h6': $vuetify.breakpoint.smAndDown }"
                        >
                            {{ $t(`views.home.slides[1].subtitle`) }}
                        </div>
                    </v-col>
                </v-row>
            </v-carousel-item>
        </v-carousel>
        <v-container fluid class="px-md-16 py-md-8 pa-8 secondary">
            <v-row justify="center">
                <v-col cols="6" sm="3" md="2" align="center">
                    <v-icon color="primary" size="60">mdi-home</v-icon>
                    <div class="mt-4 text-uppercase">
                        {{ $t(`views.irrigation.items[0].title`) }}
                    </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" align="center">
                    <v-icon color="primary" size="60">mdi-office-building</v-icon>
                    <div class="mt-4 text-uppercase">
                        {{ $t(`views.irrigation.items[1].title`) }}
                    </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" align="center">
                    <v-icon color="primary" size="60">mdi-factory</v-icon>
                    <div class="mt-4 text-uppercase">
                        {{ $t(`views.irrigation.items[2].title`) }}
                    </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" align="center">
                    <v-icon color="primary" size="60">mdi-golf</v-icon>
                    <div class="mt-4 text-uppercase">
                        {{ $t(`views.irrigation.items[3].title`) }}
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid class="px-md-16 py-md-8 pa-8 mb-8 mb-sm-0 primary white--text">
            <v-row justify="center">
                <v-col cols="12" align="center" class="main-font text-uppercase text-h5">
                    <v-icon left size="30" color="white" class="pb-2">mdi-flag-checkered</v-icon>
                    {{ $t(`views.home.video.title`) }}
                </v-col>
                <v-col cols="12" align="center" style="max-width: 1200px;">
                    <div v-for="(text, t) in $t(`views.home.video.text`)" :key="t">
                        {{ text }}
                    </div>
                </v-col>
                <v-col cols="12" align="center">
                    <iframe
                        style="max-height: unset; width: 100%; max-width: 720px; height: auto; aspect-ratio: 16 / 9;"
                        src="https://www.youtube.com/embed/TVxYPBH9TDU?si=es7Ta2uhSINcbrIz"
                        title="YouTube video player"
                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                    ></iframe>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "home-view"
    };
</script>

<style scoped>
    #carousel {
        position: relative;
        height: 70vh !important;
        padding: 0;
        overflow: hidden;
    }
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
        z-index: -2;
    }

    @media screen and (min-width: 960px) and (max-width: 1264px) { /* md */
        video {
            width: 1350px;
        }
    }
    @media screen and (min-width: 600px) and (max-width: 960px) { /* sm */
        video {
            width: 1100px;
        }
    }
    @media screen and (max-width: 600px) { /* xs */
        #carousel {
            height: 50vh !important;
        }
        video {
            width: 800px;
        }
    }

    .text-shadow {
        text-shadow: 0px 0px 8px black;
    }

    #car {
        background-position: center 60%;
    }
</style>