<template>
    <v-btn
        :large="!icon"
        text :outlined="!icon" :icon="icon"
        dark
        @click="update"
    >
        <v-icon :left="!icon">mdi-web</v-icon>
        <span v-if="!icon">{{ locale }}</span>
        <v-badge
            v-if="icon"
            color="accent" bottom offset-x="26" offset-y="-16"
            :content="locale"
        ></v-badge>
    </v-btn>
</template>

<script>
    export default {
        name: "language-element",
        props: {
            icon: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            locale() { return this.$i18n.locale == "fr" ? "en" : "fr" }
        },
        methods: {
            update() {
                this.$i18n.locale = this.$i18n.locale == 'fr' ? 'en' : 'fr';
                this.$vuetify.lang.current = this.$i18n.locale;
            }
        }
    };
</script>