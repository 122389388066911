<template>
    <ContentLayout>
        <v-row justify="center" v-for="(item, i) in items" :key="i" class="mb-8">
            <v-col cols="12" align="center" class="main-font text-uppercase text-h5 primary--text">
                <v-icon left size="30" color="primary" class="pb-2">{{ item.icon }}</v-icon>
                {{ $t(`views.irrigation.items[${i}].title`) }}
            </v-col>
            <v-col cols="12" align="center">
                <div v-for="(text, t) in $t(`views.irrigation.items[${i}].text`)" :key="t">
                    {{ text }}
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" v-for="(image, i) in item.images" :key="i">
                <v-card :class="{ 'ma-2': $vuetify.breakpoint.mdAndUp }" class="image">
                    <v-img
                        color="primary" height="300"
                        :src="image"
                        @click="overlay.images = item.images; overlay.current = image; overlay.value = true"
                    ></v-img>
                </v-card>
            </v-col>
        </v-row>
        <ImageCarouselOverlay
            :value="overlay.value"
            :images="overlay.images"
            :current="overlay.current"
            @close="overlay.value = false"
            cycle hide-delimiters
        ></ImageCarouselOverlay>

        <v-card color="primary" dark class="mt-8">
            <v-card-text>
                <v-row justify="center">
                    <v-col cols="12" align="center" class="main-font text-uppercase text-h5 white--text mt-3">
                        <v-icon left size="30">mdi-clipboard-check-outline</v-icon>
                        {{ $t(`views.irrigation.plan`) }}
                    </v-col>
                    <v-col cols="12">
                        <a
                            href="/assets/plan.pdf"
                            target="_blank"
                        >
                            <v-hover>
                                <template v-slot:default="{ hover }">
                                    <div id="plan">
                                        <v-card>
                                            <v-img
                                                color="primary" height="300"
                                                src="@/assets/img/other/plan.jpg"
                                            ></v-img>
                                        </v-card>
                                        <v-fade-transition>
                                            <v-overlay
                                                v-if="hover"
                                                absolute
                                                color="secondary"
                                                z-index="4"
                                            >
                                                <v-btn icon x-large>
                                                    <v-icon size="60" color="primary">mdi-eye</v-icon>
                                                </v-btn>
                                            </v-overlay>
                                        </v-fade-transition>
                                    </div>
                                </template>
                            </v-hover>
                        </a>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    import ImageCarouselOverlay from "@/components/elements/ImageCarouselOverlay";

    export default {
        name: "irrigation-view",
        components: { ContentLayout, ImageCarouselOverlay },
        data: () => ({
            items: [
                {
                    // residential
                    icon: "mdi-home",
                    images: [
                        require("@/assets/img/projects/residentiel-1.jpg"),
                        require("@/assets/img/projects/residentiel-2.jpg"),
                        require("@/assets/img/projects/arrosage-paysager-2.jpg"),
                        require("@/assets/img/projects/arrosage-paysager-1.jpg"),
                    ]
                },
                {
                    // commercial
                    icon: "mdi-office-building",
                    images: [
                        require("@/assets/img/projects/jean-coutu.jpg"),
                        require("@/assets/img/projects/toyota.jpg"),
                        require("@/assets/img/projects/bmw-1.jpg"),
                        require("@/assets/img/projects/desjardins-1.jpg"),
                        require("@/assets/img/projects/paquette-1.jpg"),
                    ]
                },
                {
                    // industrial
                    icon: "mdi-factory",
                    images: [
                        require("@/assets/img/projects/cogan.jpg"),
                        require("@/assets/img/projects/gmr.jpg"),
                        require("@/assets/img/projects/icm.jpg"),
                        require("@/assets/img/projects/solarium-paris.jpg"),
                    ]
                },
                {
                    // golf/soccer
                    icon: "mdi-golf",
                    images: [
                        require("@/assets/img/projects/golf-2.jpg"),
                        require("@/assets/img/projects/golf-3.jpg"),
                        require("@/assets/img/projects/golf-1.jpg"),
                        require("@/assets/img/projects/soccer.jpg"),
                    ]
                }
            ],
            overlay: {
                images: [],
                value: false,
                current: null
            }
        })
    };
</script>

<style scoped>
    .image {
        cursor: zoom-in;
    }
    #plan {
        position: relative;
    }
</style>