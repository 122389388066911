import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import en from '@/locales/en.json';
import fr from '@/locales/fr.json';

export const i18n = new VueI18n({
    locale: window.navigator.language.slice(0, 2),
    fallbackLocale: "fr",
    messages: { en, fr }
});